import React, { Fragment } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import ContactPerson from "../../components/contactPerson";
import Testimonial from "../../components/testimonial";
import { GatsbyImage } from "gatsby-plugin-image";
import { PartnersGrid } from "../../components/partnersGrid";

import service_strategy from "../../assets/images/services/strategy.svg";
import service_cms from "../../assets/images/services/cms.svg";
import service_design from "../../assets/images/services/design.svg";
import service_frontend from "../../assets/images/services/frontend.svg";
import service_backend from "../../assets/images/services/backend.svg";
import service_projectmanagement from "../../assets/images/services/projectmanagement.svg";

import generali from "../../assets/images/logos/svg/generali.svg";
import dak_gesundheit from "../../assets/images/logos/svg/dak-gesundheit.svg";
import tk from "../../assets/images/logos/svg/tk.svg";
import eppendorf from "../../assets/images/logos/svg/eppendorf.svg";
import swp from "../../assets/images/logos/svg/swp.svg";
import cosmos from "../../assets/images/logos/svg/cosmos.svg";
import hansemerkur_green from "../../assets/images/logos/svg/hansemerkur_green.svg";
import bgw_green from "../../assets/images/logos/svg/bgw_green.svg";
import haspa_green from "../../assets/images/logos/svg/haspa_green.svg";

const Leistungsportfolio = () => {
  const images = useStaticQuery(graphql`
    {
      dirk: file(relativePath: { eq: "profiles/dirk-schroedter.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const Testimonials = [
    {
      image: hansemerkur_green,
      name: "Katrin Menzel",
      position: "Senior Online Marketing Manager bei der HanseMerkur",
      text: "Der Versicherungsfinder, umgesetzt mit Formcentric, ist ein überzeugendes Tool, das für unsere Kunden einen bequemen Weg zu genau den Informationen bietet, die sie suchen. Und die Möglichkeit, das Angebot selbstständig dynamisch zu erweitern, stärkt die Präsenz der HanseMerkur im Netz heute und für die Zukunft.",
      linkTo: "hansemerkur",
    },
    {
      image: bgw_green,
      name: "Oliver Willhöft",
      position: "Bereichsleiter Softwaretechnologien bei der BGW",
      text: (
        <Fragment>
          Der Auftritt ist mit dem neuen und modernen Design gelungen. Auch der
          neue technologische Ansatz und Automatisierungs&shy;möglichkeiten
          werden uns in der Zukunft mit Sicherheit helfen, schneller und
          flexibler agieren zu können.
        </Fragment>
      ),
      linkTo: "bgw",
    },
    {
      image: haspa_green,
      name: "Peter Hußmann",
      position:
        "Bereich Informationstechnologie und Organisation bei der Haspa",
      text: "Ob Design, neuen Funktionen oder der Pflege des Content-Management-Systems – mit Monday Consulting haben wir einen vertrauenswürdigen Partner auf Augenhöhe gefunden, der unsere Anforderungen optimal umsetzt und durch eigene Ideen optimale Lösungen ermöglicht.",
      linkTo: "haspa",
    },
  ];

  return (
    <Layout lang="de" langPath="nav.serviceportfolio.link">
      <Seo
        title="Full-Service für Ihre Projekte – Monday Consulting"
        description="Wir stellen uns jeder Herausforderung und setzen Ihr Projekt nach Ihren Wünschen um. Dabei decken wir unter anderem die Bereiche Konzeption, Beratung, Entwicklung, Integration und Projektmanagement ab und begeistern kontinuierlich mit dem Mix aus fachlicher Kompetenz und familiärem Flair."
        keywords="Full-Service, Content-Management-Systeme, Design, Frontend, Backend, Infrastruktur, Projektmanagement, Strategie, Support"
      />
      <div className="parallax-translate bg-shape-circle"></div>

      <div className="container mt-4 mb-4 mb-md-5">
        <div className="row">
          <div className="col-12">
            <h1 className="h5 text-color-monday-green">
              Unser Leistungsportfolio
            </h1>
            <h2 className="text-size-2 text-size-md-1">
              Leistungen auf einen Blick
            </h2>
            <p>
              Ob Monolith oder Microservices, ob bei Ihnen im Rechenzentrum oder
              in der Cloud, unsere Berater und Entwickler erarbeiten remote oder
              vor Ort mit modernsten Technologien die passende Lösung für Sie.
              Dabei setzen wir auf höchste Sicherheit, Erreichbarkeit und
              Performance Ihrer Applikationen.
            </p>
            <p>
              Unsere kreativen Köpfe stellen sich jeder Herausforderung und
              setzen Ihr Projekt nach Ihren Wünschen um. Dabei unterstützen
              unsere agilen und klassischen Projektleiter Sie zu jeder Zeit.
              Auch die langfristige Betreuung des Systems ist durch unseren
              Support gewährleistet.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 mb-3 mb-md-0 col-md-5 bg-size-contain bg-shape-cross">
            <img
              className="w-100"
              src={service_strategy}
              alt="Wir bringen Sie auf Kurs"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Digitale Strategie & Konzeption
            </h2>
            <h3>Wir bringen Sie auf Kurs</h3>
            <p>
              Vor welcher Herausforderung Sie auch stehen, jedes Projekt beginnt
              mit einer ausgereiften digitalen Strategie und einem fundierten
              IT-Konzept. Wir nutzen unsere Expertise, um dabei explizit auf
              Ihre Anforderungen einzugehen. Ob CMS-Auswahl oder
              Systemarchitektur – wir setzen auf zukunftssichere Ansätze und
              entwickeln das optimale IT-Konzept für Ihr Unternehmen.
            </p>
            <Link to="/leistungen/strategie-konzeption" className="btn mt-1">
              Digitale Strategie & Konzeption
            </Link>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 mb-3 mb-md-0 col-md-5 bg-size-contain bg-shape-square">
            <img
              className="w-100"
              src={service_cms}
              alt="Wir sichern die Basis für Ihren Erfolg"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Content-Management-Systeme
            </h2>
            <h3>Wir sichern die Basis für Ihren Erfolg</h3>
            <p>
              Auf Basis von CoreMedia und FirstSpirit entwickeln wir
              umfangreiche Enterprise-Lösungen, die komplexe Strukturen abbilden
              und durch maximale Flexibilität überzeugen. Gleichzeitig nutzen
              wir unsere jahrelange Erfahrung auch für leichtgewichtige
              Alternativen wie Strapi. Das Headless-CMS bietet dank modernster
              Ansätze besonders viel Raum zur Individualisierung.
            </p>
            <Link
              to="/leistungen/content-management-systeme"
              className="btn mt-1"
            >
              Content-Management-Systeme
            </Link>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 mb-3 mb-md-0 col-md-5 bg-size-contain bg-shape-circle">
            <img
              className="w-100"
              src={service_design}
              alt="Wir gestalten Ihre Kundenbeziehungen"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">Design & Kreation</h2>
            <h3>Wir bringen Kreation und Technik zusammen</h3>
            <p>
              Unsere kreativen Experten lassen Ihren Internetauftritt im neuen
              Glanz erstrahlen. Dabei sorgen wir dafür, dass der neue Look Ihrer
              Webseite nicht nur optisch, sondern vor allem durch die Methode
              dahinter überzeugt. Wir haben bei der Gestaltung immer die
              Entwicklung im Blick und arbeiten mit modernsten Techniken, um auf
              allen Kanälen das bestmögliche Ergebnis für Sie zu erreichen.
            </p>
            <Link to="/leistungen/design-kreation" className="btn mt-1">
              Design & Kreation
            </Link>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 mb-3 mb-md-0 col-md-5 bg-size-contain bg-shape-diamond">
            <img
              className="w-100"
              src={service_frontend}
              alt="Wir fördern Ihre digitalen Kundenbeziehungen"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Frontend & User Experience
            </h2>
            <h3>Wir fördern Ihre digitalen Kundenbeziehungen</h3>
            <p>
              Wir entwickeln Webseiten, die nicht nur schön, sondern vor allem
              funktional, schnell und ausfallsicher sind. Dabei vertrauen wir
              auf modernste Programmiersprachen und Frameworks, die eine erhöhte
              Entwicklungsgeschwindigkeit und optimale User Experience
              gewährleisten. Die Webseiten-Performance wirkt sich nicht nur
              positiv auf die Kundenzufriedenheit, sondern auch auf Ihr
              Suchmaschinen-Ranking aus.
            </p>
            <Link
              to="/leistungen/frontend-user-experience"
              className="btn mt-1"
            >
              Frontend & User Experience
            </Link>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 mb-3 mb-md-0 col-md-5 bg-size-contain bg-shape-dots">
            <img
              className="w-100"
              src={service_backend}
              alt="Wir bringen Sie nachhaltig nach vorne"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Backend & Infrastruktur
            </h2>
            <h3>Wir bringen Sie nachhaltig nach vorne</h3>
            <p>
              Unser Fokus liegt auf der Entwicklung moderner Infrastrukturen.
              Unsere Experten sind mit den führenden Technologien bestens
              vertraut, um Ihre Architektur modern, wartbar und performant zu
              gestalten. Dank Containerarchitekturen und dem Einsatz von
              Microservices verkürzt sich der Entwicklungszyklus, sowie das
              Deployment und die kontinuierliche Weiterentwicklung.
            </p>
            <Link to="/leistungen/backend-infrastruktur" className="btn mt-1">
              Backend & Infrastruktur
            </Link>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 mb-3 mb-md-0 col-md-5 bg-size-contain bg-shape-vertigo">
            <img
              className="w-100"
              src={service_projectmanagement}
              alt="Wir begleiten Sie in jeder Situation"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Projektmanagement & Support
            </h2>
            <h3>Wir begleiten Sie in jeder Situation</h3>
            <p>
              Während des gesamten Projektablaufs stehen Ihnen unsere
              zertifizierten Projektmanager partnerschaftlich zur Seite. Um Ihr
              Projekt erfolgreich umzusetzen, setzen wir auf kontinuierliche
              Verbesserung der gemeinsamen Prozesse. So erreichen wir optimale
              Ergebnisse und sorgen für den langfristigen Erfolg Ihres Projekts.
              Anschließend übernimmt unser Customer Care Helpdesk, um Sie
              langfristig und bestmöglich zu betreuen.
            </p>
            <Link
              to="/leistungen/projektmanagement-support"
              className="btn mt-1"
            >
              Projektmanagement & Support
            </Link>
          </div>
        </div>
      </div>

      <PartnersGrid
        title="Spezialisierung für Ihren Erfolg"
        teaser={
          "Mit unseren starken Partnerschaften gestalten wir barrierefreie und individuelle Erlebnisse von morgen."
        }
      />

      <div
        className="content-container vertical-spacing bg-monday-green bg-pattern-vertigo text-color-white text-center parallax-background"
        data-spacing-bottom="testimonials"
      >
        <div className="container pt-4 pt-md-5 pb-3">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h2 className="mb-3">
                Diese Unternehmen setzen auf Monday Consulting
              </h2>
              <p>
                Unternehmen aus diversen Branchen verbessern mit unseren
                individuellen und zukunftssicheren Lösungen den Dialog mit den
                Kunden sowie interne Prozesse und sichern so ihren Erfolg am
                Markt. Dabei gleicht kein Case dem anderen und das macht uns
                besonders stolz.
              </p>
              <Link className="btn" to="/referenzen">
                Unsere Referenzen
              </Link>
            </div>
          </div>
          <div className="row mt-4 mb-2 align-items-center justify-content-center">
            <div className="p-2 flex-grow-0 mb-2">
              <img src={eppendorf} alt="Eppendorf Gruppe" height="30px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={dak_gesundheit} alt="DAK-Gesundheit" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={cosmos} alt="CosmosDirekt" height="70px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={tk} alt="Techniker Krankenkasse" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={swp} alt="Stadtwerke Potsdam" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={generali} alt="Generali" height="50px" />
            </div>
          </div>
        </div>
      </div>

      <div
        id="testimonials"
        className="container vertical-offset mb-5 mb-md-6 px-0 px-md-3"
        data-offset="50%"
      >
        <div className="swiper-container reveal">
          <div className="swiper-wrapper">
            {Testimonials.map((item, i) => (
              <Testimonial key={i} {...item} />
            ))}
          </div>
          <div className="swiper-pagination d-xl-none"></div>
        </div>
      </div>

      <ContactPerson
        headline="Dürfen wir Ihnen behilflich sein?"
        text="Überzeugen Sie sich noch heute von unseren Leistungen und nehmen Sie direkt Kontakt mit uns auf. Wir stehen Ihnen mit Rat und Tat zur Seite."
        contactEmail="business@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Dirk Schrödter, Geschäftsführer"
      >
        <GatsbyImage
          image={images.dirk.childImageSharp.gatsbyImageData}
          alt="Dirk Schrödter"
        />
      </ContactPerson>
    </Layout>
  );
};

export default Leistungsportfolio;
